
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import NotificationService from "@/buying-teams/services/NotificationService";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CreatePassword",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const showPass = ref(false);
    const showConfirmPass = ref(false);
    const confirmPassword = "";
    const password = "";

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const createPassword = Yup.object().shape({
      password: Yup.string().required(t('Password is a required field')),
      confirmPassword: Yup.string().required(t('Confirm Password is a required field'))
        .oneOf([Yup.ref('password'), null], t('Passwords must match'))
    });

    const authControlEmail = store.getters.authControlConfigs.email;

    onMounted(async () => {
      if (!authControlEmail) {
        redirectToRouteWithName("sign-in");
      }
    });

    //Form submit function
    const onSubmitCreatePassword = async (values) => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      if (values.password === values.confirmPassword) {
        store.commit("setAuthConfigPassword", values.password);
        await verifyEmail()
          .then(response => {
            redirectToRouteWithName("verify");
          })
          .catch(err => {
            redirectToRouteWithName("sign-in");
            NotificationService.swalError(err);
            console.log("err = ", err);
          });
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    async function verifyEmail() {
      let emailData = {
        email: authControlEmail
      };

      await store.dispatch("verifyEmail", emailData);
    }

    return {
      onSubmitCreatePassword,
      createPassword,
      submitButton,
      password,
      confirmPassword,
      showPass,
      showConfirmPass
    };
  }
});
